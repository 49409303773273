<template>
  <div>
    <el-row type="flex">
      <el-col class="custom_txt custom_border" :span="24">
        {{ $t('askSpread') }}
      </el-col>
    </el-row>
    <el-tabs
      animated
      v-model="currentTab.routeName"
      class="custom-tabs background-day"
      type="card"
      :stretch="true"
      :lazy="true"
      @tab-click="termClick(currentTab.routeName)"
    >
      <el-tab-pane
        v-for="(item, idx) in menus"
        :label="upperCase($t(item.name))"
        :name="item.routeName"
        :key="idx"
      >
      </el-tab-pane>
    </el-tabs>
    <transition :name="transitionName">
      <router-view class="transition" />
    </transition>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import wallet from '@/common/web/wallet/wallet.vue';
import {ElTabs, ElTabPane} from 'element-plus';
import {Watch} from 'vue-property-decorator';
import {App, Format} from '@/services';

@Options({
  components: {
    wallet,
    ElTabs,
    ElTabPane
  }
})
export default class Proxy extends Vue {
  transitionName = 'slide-fade-right';
  currentTab = {
    name: 'ui_act_rp',
    routeName: 'ProxyReport'
  };
  mounted() {
    console.log(this.$route.name, 'this.$route.name', this.menus);
    // this.currentTab = this.menus.filter(v => v.routeName === this.$route.name)[0];

    this.currentTab = {...this.menus.filter(v => v.routeName === this.$route.name)[0]};
  }

  upperCase = Format.upperCase;
  menus = [
    {
      name: 'topicList',
      routeName: 'topicList'
    },
    {
      name: 'participateUser',
      routeName: 'participateUser'
    }
  ];

  @Watch('$route')
  onChange() {
    const isBack = App.getIsRouterBack();
    App.setIsRouterBack(false);
    this.transitionName = isBack ? 'slide-fade-left' : 'slide-fade-right';
    this.currentTab = {...this.menus.filter(v => v.routeName === this.$route.name)[0]};
  }

  termClick(name: string) {
    this.$router.push({name});
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__label {
  float: none;
}
.text-day {
  color: $cf;
}
.text-gold {
  color: $gold;
}
.text-darkgray {
  color: $c9;
}
.background-primary {
  background-color: $bg;
}
.background-day {
  background-color: $cf;
}
.u_w--100p {
  width: 100%;
}
.u_t--center {
  text-align: center;
}
.custom_txt {
  border: none !important;
  vertical-align: middle;
  height: auto;
  font-size: 24px;
  margin: auto;
  font-weight: 700;
}
.custom_border {
  padding: 10px;
  margin-bottom: 20px;
}
.u_m--l10 {
  margin-left: 10px;
}
.u_m--l20 {
  margin-left: 20px;
}
.u_m--r10 {
  margin-right: 10px;
}
.u_m--b50 {
  margin-bottom: 50px;
}
.text--99 {
  color: $c9;
}
:deep(.text-night) {
  color: #000 !important;
}
:deep(.background-day) {
  background-color: $cf !important;
}
.custom-tabs {
  :deep(.el-tabs__item) {
    border: 1px solid #1f55f0 !important;
  }
  :deep(.el-tabs__item:hover) {
    background-color: transparentize(#1f55f0, 0.3);
    color: #ffffff;
  }
  :deep(.el-tabs__item.is-active) {
    background-color: #1f55f0;
    color: #ffffff;
  }
}
</style>
